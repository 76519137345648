<template>
  <div class="page bg-white" id="reorganize">
    <el-form
        class="foreaa"
        size="small"
        ref="inputForm"
        :model="inputForm"
        :rules="rules"
        label-width="100px"
    >
      <el-row :gutter="20">
        <el-col :span="10">
          <el-form-item label="展览名称" prop="exhibitionName">
            <el-input
                v-model.trim="inputForm.exhibitionName"
                placeholder="请输入展览名称(限50字)"
                maxlength="50"
                :disabled="inputType"
                clearable
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item label="展览类型" prop="exhibitionType">
            <el-select
                v-model="inputForm.exhibitionType"
                clearable
                :disabled="inputType"
                placeholder="请选择展览类型"
                style="width: 100%"
            >
              <el-option
                  v-for="item in this.$dictUtils.getDictList('exhibition_type')"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item label="展览时间" prop="exhibitionTime">
            <el-date-picker
                style="width: 100%"
                v-model="inputForm.exhibitionTime"
                :disabled="inputType"
                type="daterange"
                value-format="yyyy-MM-dd"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="20">
          <el-form-item label="展览概述" prop="exhibitionSummarize">
            <el-input
                v-model="inputForm.exhibitionSummarize"
                clearable
                type="textarea"
                :rows="3"
                maxlength="500"
                :disabled="inputType"
                style="width: 100%"
                placeholder="请填写展览概述（限500字）"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-button size="small" type="primary" :disabled="inputForm.exhibitionChapters.length == 200"
                 v-if="!inputType" @click="addexhibition()">添加展览信息
      </el-button>
      <el-table
          :data="inputForm.exhibitionChapters"
          size="small"
          ref="multipleTable"
          class="table"
          height="calc(100vh - 435px)"
          :header-cell-style="{ background: '#F9DFDF' }"
      >
        <el-table-column label="序号" type="index" width="50">
          <template slot-scope="scope">
            <el-form-item label-width="0">
              {{ scope.$index + 1 }}
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column prop="chapterName" label="展览章节" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-form-item label-width="0" :prop="`exhibitionChapters[${scope.$index}][chapterName]`"
                          :rules="[ { required: true, message: '请填写章节名称', trigger: 'blur' },]">
              <div v-if="scope.row.editItem">{{ scope.row.chapterName }}</div>
              <el-input
                  v-else
                  v-model.trim="scope.row.chapterName"
                  placeholder="请输入章节名称(限200字)"
                  maxlength="200"
                  :disabled="inputType"
                  clearable
              ></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column prop="exhibitionLocation" label="展览位置" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-form-item label-width="0" :prop="`exhibitionChapters[${scope.$index}][exhibitionLocation]`"
                          :rules="[  { required: true, message: '请填写展览位置', trigger: 'blur' },]">
              <div v-if="scope.row.editItem">{{ scope.row.exhibitionLocation }}</div>
              <el-input
                  v-else
                  v-model.trim="scope.row.exhibitionLocation"
                  placeholder="请输入展览位置(限200字)"
                  maxlength="200"
                  :disabled="inputType"
                  clearable
              ></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column prop="exhibitionMethod" label="陈列展览方法" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-form-item label-width="0" :prop="`exhibitionChapters[${scope.$index}][exhibitionMethod]`"
                          :rules="[  {  required: true,   message: '请填写陈列展览方法', trigger: 'blur', },]">
              <div v-if="scope.row.editItem">{{ scope.row.exhibitionMethod }}</div>
              <el-input
                  v-else
                  v-model.trim="scope.row.exhibitionMethod"
                  placeholder="请输入陈列展览方法(限200字)"
                  maxlength="200"
                  :disabled="inputType"
                  clearable
              ></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column prop="exhibitionTheme" label="陈列展览主题" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-form-item label-width="0" :prop="`exhibitionChapters[${scope.$index}][exhibitionTheme]`"
                          :rules="[ { required: true, message: '请填写陈列展览主题', trigger: 'blur', }, ]">
              <div v-if="scope.row.editItem">{{ scope.row.exhibitionTheme }}</div>
              <el-input
                  v-else
                  v-model.trim="scope.row.exhibitionTheme"
                  placeholder="请输入陈列展览主题(限200字)"
                  maxlength="200"
                  :disabled="inputType"
                  clearable
              ></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="陈列藏品" width="80px">
          <template slot-scope="scope">
            <el-form-item label-width="0">
              <el-button v-if="butType == 1 || inputType" size="mini" type="text"
                         @click="addColl(scope.row,1)">详情
              </el-button>
              <el-button v-else size="mini" type="text" @click="addColl(scope.row,0)">添加
              </el-button>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="180" v-if="!inputType">
          <template slot-scope="scope">
            <el-form-item label-width="0">
              <el-button size="mini" type="text" v-if="scope.row.editItem"
                         @click="scope.row.editItem = false">修改
              </el-button>
              <el-button size="mini" type="text" v-else @click="saveDataMessage(scope.row)">
                保存
              </el-button>
              <el-button size="mini" type="text" @click="deleteData(scope.$index)">删除
              </el-button>
            </el-form-item>
          </template>
        </el-table-column>
      </el-table>
    </el-form>
    <div class="text_center" style="margin-top: 10px" v-if="!inputType">
      <el-button size="small" @click="returnPage(1)">取消</el-button>
      <el-button size="small" type="primary" @click="preview()">预览</el-button>
    </div>
    <div v-else-if="butType == 1 || butType == 4" class="text_center" style="margin-top: 10px">
      <el-button v-if="butType == 4 && !see" size="small" type="primary" @click="switchData(1)">上一条</el-button>
      <el-button v-if="butType == 4 && !see" size="small" type="primary" @click="switchData(0)">下一条</el-button>
      <el-button size="small" v-if="butType == 4 && see != 1 && this.inputForm.auditStatus == 1" type="primary"
                 @click="through(3)">通过
      </el-button>
      <el-button size="small" v-if="butType == 4 && see != 1 && this.inputForm.auditStatus == 1" type="danger"
                 @click="through(2)">驳回
      </el-button>
      <el-button size="small" @click="returnPage(1)">关闭</el-button>
    </div>
    <div v-else class="text_center" style="margin-top: 10px">
      <el-button size="small" @click="returnPage(1)">取消</el-button>
      <el-button size="small" type="primary" @click="inputType = false">上一步</el-button>
      <el-button size="small" v-if="inputForm.auditStatus== 3" v-no-more-click type="primary" @click="subtitew(0)">保存
      </el-button>

      <el-button size="small" v-if="inputForm.auditStatus!= 3" v-no-more-click type="primary" @click="subtitew(0)">保存为草稿
      </el-button>
      <el-button size="small" v-if="inputForm.auditStatus!= 3" v-no-more-click type="primary" @click="subtitew(1)">提交
      </el-button>
    </div>
    <collection-inventory ref="inventory"></collection-inventory>
    <reviewSuggestions ref="suggestions" @updataStatus="returnPage()"></reviewSuggestions>
  </div>
</template>

<script>

import CollectionInventory from "@/views/modules/collection/business/module/collectionInventory.vue";
import reviewSuggestions from "@/views/modules/collection/accounts/module/reviewSuggestions.vue";

export default {
  name: "exhibitionDetail",
  components: {reviewSuggestions, CollectionInventory},
  data() {
    return {
      exhibitionId: '',
      inputForm: {
        exhibitionName: "",
        exhibitionType: "",
        exhibitionSummarize: "",
        exhibitionChapters: [],

        exhibitionTime: [],
        beginDate: '',
        endDate: '',
      },
      rules: {
        exhibitionName: [
          {required: true, message: "请填写展览名称", trigger: "blur"},
        ],
        exhibitionTime: [
          {type: 'array', required: true, message: "请选择展览时间", trigger: "change"},
        ],
      },
      registrationSearch: {},

      programExit: false,
      inputType: false,
      returnPageStatus: '',
      butType: "", //0新增 1详情 2修改

      allcollData: [],
      see: false,
    };
  },

  mounted() {
    this.exhibitionId = this.$route.query.exhibitionId
    this.butType = this.$route.query.butType
    this.see = this.$route.query.see
    if (this.butType == 1 || this.butType == 4) {
      this.inputType = true
    }
    if (this.exhibitionId) {
      this.getDataDetail()
    } else {
      this.addexhibition()
    }
    this.setPageTitle()
  },

  methods: {
    getDataDetail() {
      this.$axios(this.api.collection.exhibitioninfoGetById + this.exhibitionId, {}, 'get').then((res) => {
        if (res.status) {
          this.inputForm = res.data
          this.inputForm.exhibitionChapters.forEach(item1 => {
            this.$set(item1, 'exhibitionChapterCollection', item1.exhibitionChapterCollectionVO ? item1.exhibitionChapterCollectionVO.dataList : [])
            this.$set(item1, 'editItem', true)
          })
          if (this.inputForm.beginDate) {
            this.$set(this.inputForm, 'exhibitionTime', [this.inputForm.beginDate, this.inputForm.endDate])
          }
        }
      })
    },

    addexhibition() {
      this.inputForm.exhibitionChapters.push(
          {
            chapterName: "",
            exhibitionLocation: '',
            exhibitionMethod: '',
            exhibitionTheme: '',
            exhibitionChapterCollection: [],//藏品集合
            editItem: false, //是否编辑
          },
      )
    },

    //添加藏品 0添加 1查看
    addColl(row, num) {
      if (num == 0) {
        this.allcollData = []
        this.inputForm.exhibitionChapters.forEach(item => {
          this.allcollData.push(...item.exhibitionChapterCollection)
        })
      }
      this.$refs.inventory.init(row.exhibitionChapterCollection, num, this.allcollData, this.inputForm.auditStatus)
    },

    //保存展览信息
    saveDataMessage(row) {
      if (row.chapterName == '' || row.exhibitionLocation == '' || row.exhibitionMethod == '' || row.exhibitionTheme == '') {
        this.$message.error('请填写完整展览信息')
        return
      }
      if(row.exhibitionChapterCollection.length == 0){
        this.$message.error('请添加陈列藏品信息')
        return
      }
      row.editItem = true
    },

    //删除展览信息
    deleteData(index) {
      if (this.inputForm.exhibitionChapters.length == 1){
        this.$message.error('至少保留一条展览信息')
        return
      }
      this.$confirm(`您是否确认删除该展览信息?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.inputForm.exhibitionChapters.splice(index, 1)
      })
    },

    //预览
    preview() {
      this.$refs['inputForm'].validate((valid) => {
        let editData = this.inputForm.exhibitionChapters.filter(item => {
          return item.editItem == false
        })
        if (!editData.length) {
          if (valid) {
            this.inputType = true
          } else {
            console.log('error submit!!');
            return false;
          }
        } else {
          this.$message.error('您还有展览信息还没保存，请先保存')
        }
      });
    },

    //0保存为草稿 1提交
    subtitew(num) {
      this.inputForm.exhibitionChapters.forEach(item => {
        item.exhibitionChapterCollection.forEach(item2 => {
          this.$set(item2, 'dataId', item2.id)
        })
      })
      if (this.inputForm.exhibitionTime && this.inputForm.exhibitionTime.length == 2) {
        this.inputForm.beginDate = this.inputForm.exhibitionTime[0]
        this.inputForm.endDate = this.inputForm.exhibitionTime[1]
      } else {
        this.inputForm.beginDate = ''
        this.inputForm.endDate = ''
      }

      let data = {
        exhibitionId: this.exhibitionId,
        ...this.inputForm,
        auditStatus: num,
      }
      if (this.inputForm.auditStatus == 3) {
        data.auditStatus = 3
      }
      if (this.exhibitionId) {
        this.$axios(this.api.collection.exhibitioninfoById, data, 'put').then((res) => {
          if (res.status) {
            this.$message.success(num == 0 ? '保存成功！' : '提交成功！')
            this.programExit = true
            this.returnPage(1)
          } else {
            this.$message.error(res.msg);
          }
        })
      } else {
        this.$axios(this.api.collection.exhibitioninfoSave, data, 'post').then((res) => {
          if (res.status) {
            this.$message.success(num == 0 ? '保存成功！' : '提交成功！')
            this.programExit = true
            this.returnPage(1)
          } else {
            this.$message.error(res.msg);
          }
        })
      }
    },

    //type 2驳回 3通过
    through(type) {
      this.$set(this.inputForm, 'id', this.exhibitionId)
      this.$refs.suggestions.init(2, [this.inputForm], type, 11, this.api.collection.exhibitioninfoExamine)
    },

    //1为上一条 0为下一条
    switchData(num) {
      if (Object.keys(this.registrationSearch).length == 0) {
        this.registrationSearch = JSON.parse(sessionStorage.getItem('registrationSearch'))
      }
      if (num == 0) {
        this.registrationSearch.current++
      } else {
        if (this.registrationSearch.current = 1) {
          this.$message.error('已是第一条')
        } else {
          this.registrationSearch.current--
        }
      }
      this.$axios(this.api.collection.exhibitioninfoList, this.registrationSearch, 'get').then((res) => {
        if (res.status) {
          if (res.data && res.data.records.length) {
            this.exhibitionId = res.data.records[0].exhibitionId
            this.getDataDetail()
          } else {
            this.registrationSearch.current--
            this.$message.error(`已是${num == 1 ? '第一' : '最后'}一条`);
          }
        } else {
          this.$message.error(res.msg);
        }
      })
    },

    //返回
    returnPage(num) {
      this.returnPageStatus = num
      this.$router.back()
    },

    setPageTitle() {
      let name = '展览详情'
      if (this.butType == 0) {
        name = '展览新增'
      } else if (this.butType == 2) {
        name = '展览修改'
      }
      this.$store.state.tagsView.visitedViews.forEach(item => {
        if (item.fullPath === this.$route.fullPath) {
          item.title = name
          return
        }
      })
    },

  },

  //离开此页面
  beforeRouteLeave(to, from, next) {
    // 在这里编写逻辑来决定是否允许离开
    // 如果允许离开，调用 next() 方法
    // 如果不允许离开，调用 next(false) 方法
    if (!this.returnPageStatus) {
      sessionStorage.removeItem('listSearch')
    }
    if (this.programExit) {
      this.$store.dispatch('tagsView/delView', this.$route).then(({visitedViews}) => {
      })
      next();
    } else if (this.butType == 0) {
      this.$confirm("当前内容没有保存，您确定离开吗？", "提示", {
        confirmButtonText: "退出",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
            //进去别的页面
            if (this.returnPageStatus) {
              this.$store.dispatch('tagsView/delView', this.$route).then(({visitedViews}) => {
              })
            }
            next();
          })
          .catch(() => {
            next(false);
          });
    } else {
      next();
    }
  },
};
</script>

<style scoped>
.bg-white {
  overflow-y: auto !important;
}

.table ::v-deep .el-form-item {
  /*margin-bottom: 0 !important;*/
}
</style>
